import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 100%;
  min-height: 100vh;
  background-color: #35363c;
`;

export const Navbar = styled.div`
  position: absolute;
  top: 0;
  width: calc(100% - 200px);
  font-family: GlamourAbsolute;
  font-size: 1.3rem;

  margin: 50px 100px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1200px) {
    width: 80%;
    margin: 50px 0;
  }
  @media (max-width: 900px) {
    width: 80%;
    margin: 50px auto;
  }

`;

export const NavbarLeft = styled(Link)`
  text-decoration: none;
  display: inline-block;
  display: flex;
  align-items: center;
  
  img {
    height: 2.5rem;
    display: inline-block;
    margin-right: 20px;
  }
  
  p {
    display: inline-block;
  }

  @media (max-width: 900px) {
    p {
      display: none;
    }
  }
`;

export const NavbarRight = styled.div`
  display: inline-block;
`;

export const NavbarLink = styled(Link)`
  text-decoration: none;
  margin-left: 30px;
`;

export const TextWrapper = styled.div`
  width: 60%;
  margin-top: 15rem;
  margin-bottom: 5rem;
  max-width: 1000px;
  
  text-align: center;

  @media (max-width: 1200px) {
    width: 80%;
  }
  @media (max-width: 900px) {
    width: 95%;
  }
`;

export const Title = styled.h1`
  font-style: normal;
  font-size: 4rem;
  word-spacing: 1rem;

  margin: 20px 0;

  span {
    font-family: GlamourAbsoluteAlternate, serif;
  }

  @media (max-width: 1200px) {
    font-size: 3rem;
  }
  @media (max-width: 900px) {
    word-spacing: 0rem;;
    font-size: 2rem;
  }
`;

interface SubtitleProps {
  small?: boolean
}

export const Subtitle = styled.h1<SubtitleProps>`
  font-style: normal;
  font-family: Proxima Nova, sans-serif;
  font-weight: 700;
  font-size: ${props => props.small ? '1rem' : '2rem'};

  @media (max-width: 1200px) {
    font-size: ${props => props.small ? '1rem' : '1.5rem'};
  }
  @media (max-width: 900px) {
    font-size: ${props => props.small ? '1rem' : '1.2rem'};
  }
`;


export const NewCourt = styled.img`
  max-width: 600px;
  width: 100%;
`;

export const Social = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  a {
    padding: 10px;

    svg {
      font-size: 1.5rem;
    }

    &:hover {
      color: #ddd;
    }
  }
`;

export const TicketingLink = styled.h1`
  font-size: 2rem;

  border: 1px solid #fff;
  padding: 10px 20px;
  display: inline-block;
  border-radius: 5px;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Space = styled.div`
  height: 3rem;
`;

export const FAQ = styled.div`
  text-align: left;

  h3 {
    font-size: 1.3rem;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 25px;
    white-space: pre-line;
    
    span.indent {
      margin-left: 20px;
    }
  }
`;