import React, { useState, useEffect } from 'react';

// Components
import Header from '../components/Work/Header';
import Footer from '../components/Footer/Footer';

const Tickets = () => {
  return <>
    <Header />
    <Footer />
  </>
}

export default Tickets;