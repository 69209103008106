import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

// Components
import FadeIn from '../Helpers/FadeIn';

// Styles
import * as styles from './styles/HeaderStyles';

// Images
import Eagle from './../../assets/eagle.svg';
import NewCourt from './../../assets/new_court.svg';

import NavBar from '../Helpers/NavBar';

const Header: React.FC = () => {
  return <>
    <styles.Section>
          <NavBar />
      <styles.TextWrapper>
        <FadeIn triggered={true}>
          
        <styles.NewCourt src={NewCourt} />
          <styles.Space></styles.Space>
          <styles.TicketingLink><a href="https://tickets.mayball.org">Access the ticketing system</a></styles.TicketingLink>
          <styles.Space></styles.Space>
          <p></p>
          <styles.Social>
            <a href={ 'https://www.facebook.com/StJohnsCollegeMayBall' }><FontAwesomeIcon icon={faFacebook} /></a>
            <a href={ 'https://www.instagram.com/stjohnsmayball/' }><FontAwesomeIcon icon={faInstagram} /></a>
            <a href={ 'https://twitter.com/stjohnsmayball' }><FontAwesomeIcon icon={faTwitter} /></a>
          </styles.Social>
        </FadeIn>
      </styles.TextWrapper>
    </styles.Section>
  </>
}

export default Header;
