import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0px;
  }

  @font-face {
    font-family: GlamourAbsolute;
    src: url("/assets/fonts/GlamourAbsolute_Regular.woff") format('woff');
  }

  @font-face {
    font-family: GlamourAbsoluteAlternate;
    src: url("/assets/fonts/GlamourAbsolute_Alternate.woff") format('woff');
  }

  body {
    font-family: proxima-nova, sans-serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: inherit;
  }

  b {
    font-weight: 700;
  }
  
  h1,h2,h3,h4,h5,h6 {
    font-family: GlamourAbsolute, serif;
    font-weight: 400;
  }

  a:hover {
    color: white;
  }

  .tocify-header { text-indent: 0 !important; }
  .tocify-subheader { text-indent: 0 !important; padding-left: 13px !important; }
.tocify ul, .tocify li { line-height: 23px !important; }
.tocify-item a { display:block; }

  .accessibility {
    display: block;
    position: absolute;
     bottom: 0;
      right: 0;
       margin-right: 20px;
       margin-bottom: 20px;

    background: rgba(0,0,0,0.5);
    padding: 10px;
    border-radius: 50%;
    height: 89px;
  }


`;
