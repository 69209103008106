import styled from 'styled-components';
import { SectionWrapper } from './HomepageStyles';
import { Link } from 'react-router-dom';
import { lighten } from 'polished';

export const Section = styled(SectionWrapper)`
  p {
    margin: 10px 0;
    /* color: #666; */
  }
`;

interface BackgroundImageProps {
  backgroundImage: string
}

export const BackgroundWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
`;

export const BackgroundImage = styled.div<BackgroundImageProps>`
  background-image: url(${props => props.backgroundImage});
  width: 100%;
  height: calc(100% + 40vh);
  top: -40vh;
  position: absolute;
  z-index: -2;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const BackgroundGradient = styled.div`
  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 60%, #ffffff 100%);
  width: 100%;
  height: calc(100% + 40vh);
  top: -40vh;
  position: absolute;
  z-index: -1;
`;

export const TripleColumn = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  grid-column-gap: 30px;

  @media (max-width: 900px) {
    display: block;
  }
`;

export const Column = styled.div`
  width: 100%;
  height: 100%;
`;

export const Box = styled.div`
  background: rgba(255,255,255,0.95);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
  
  @media (max-width: 900px) {
    margin: 30px 0;
  }
`;

export const BoxInformation = styled.div`
  padding: 20px;
`;

export const BoxTitle = styled.p`
  font-family: proxima-nova, sans-serif;
  font-weight: 800;
  font-size: 1.2rem;
  color: ${({theme}) => theme.accentColor};
`;

export const BoxEmphasisTitle = styled.p`
  font-family: GlamourAbsolute, serif;
  font-size: 2rem;
`;

export const BoxBody = styled.p``;

interface BoxButtonProps {
  inactive?: boolean
}

export const BoxButton = styled.a<BoxButtonProps>`
  background-color: ${props => props.inactive ? lighten(0.2, props.theme.accentColor) :  props.theme.accentColor}};
  color: #fff;
  font-weight: 700;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  ${props => props.inactive ? '' : 'cursor: pointer;'}

  text-decoration: none;
`;

export const BoxButtonLink = styled(Link)`
  background-color: ${({theme}) => theme.accentColor};
  color: #fff;
  font-weight: 700;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
`;

interface BoxImageProps {
  backgroundImage: string
}

export const BoxImage = styled.div<BoxImageProps>`
  background-image: url(${props => props.backgroundImage});
  width: 100%;
  height: 150px;

  background-size: cover;
  background-repeat: no-repeat;
`;

interface BoxImageContainerProps {
  backgroundColor: string;
}

export const BoxImageContainer = styled.div<BoxImageContainerProps>`
  background-color: ${props => props.backgroundColor};
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
`;
