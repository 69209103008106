import styled from 'styled-components';

interface SectionWrapperProps {
  fullHeightMobile?: boolean
}

export const SectionWrapper = styled.div<SectionWrapperProps>`
  width: 100%;
  min-height: 100vh;

  @media (max-width: 900px) {
    ${props => props.fullHeightMobile ? '' : 'min-height: auto;' }
  }
`;

interface MainWrapperProps {
  width?: string
  fullHeightMobile?: boolean
}

export const MainWrapper = styled.div<MainWrapperProps>`
  width: ${props => props.width ? props.width : '70%'};
  max-width: 80rem;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  @media (max-width: 1200px) {
    width: ${props => props.width ? `calc(${props.width} * (8 / 7))` : '80%'};
  }

  @media (max-width: 900px) {
    width: ${props => props.width ? `calc(${props.width} * (9 / 7))` : '90%'};
    flex-direction: column;
    ${props => props.fullHeightMobile ? '' : 'min-height: auto;' }
  }
`;

export const HalfColumn = styled.div`
  width: 50%;
  display: inline-block;

  @media (max-width: 1200px) {
    width: 100%;
    display: block;
  }
`;

export const WideColumn = styled.div`
  width: 100%;
  display: inline-block;
`;

interface SectionTitleProps {
  dark?: boolean
}

export const SectionTitle = styled.p<SectionTitleProps>`
  font-family: proxima-nova, sans-serif;
  font-weight: 800;
  font-size: 1.2rem;
  color: ${props => props.dark ? props.theme.accentColorDarkBackground : props.theme.accentColor};
`;

export const EmphasisText = styled.p`
  font-family: GlamourAbsolute, serif;
  font-size: 3rem;
`;

export const BodyText = styled.p`
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
`;