import React, { useEffect, useState } from "react";

interface FadeInProps {
  triggered: boolean
}

const FadeIn: React.FC<FadeInProps> = ({children, triggered, ...props}) => {
  const [maxIsVisible, setMaxIsVisible] = useState(0);

  useEffect(() => {
    if (triggered === true) {
      const childrenCount = React.Children.count(children);
      const interval = setInterval(() => {
        setMaxIsVisible((count) => {
          if (count >= childrenCount) clearInterval(interval);

          return count + 1;
        });
        
      }, 100);

      return () => clearInterval(interval);
    }
  }, [triggered]);

  return (
    <>
      {React.Children.map(children, (child, i) => {
        return (
          <div
            style={{
              transition: `opacity 400ms, transform 400ms`,
              transform: `translateY(${maxIsVisible > i ? 0 : 20}px)`,
              opacity: maxIsVisible > i ? 1 : 0,
            }} {...props}
          >
            {child}
          </div>
        );
      })}
    </>
  );
};

export default FadeIn;