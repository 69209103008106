import React, { useState, useEffect } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

// Components
import FadeIn from './../Helpers/FadeIn';

// Styles
import * as HomepageStyles from './styles/HomepageStyles';
import * as styles from './styles/AboutStyles';

// Images
import NewCourt from './../../assets/new_court.svg';

const About = () => {
  const [fadeInAnimate, setFadeInAnimate] = useState(false);

  return <>
    <styles.Section fullHeightMobile>
      <HomepageStyles.MainWrapper fullHeightMobile>
        <HomepageStyles.HalfColumn>
          <FadeIn triggered={fadeInAnimate}>
          <VisibilitySensor onChange={isVisible => { if (isVisible) setFadeInAnimate(true) }}>
            <HomepageStyles.SectionTitle dark>ABOUT THE BALL</HomepageStyles.SectionTitle>
          </ VisibilitySensor>
          <HomepageStyles.EmphasisText>Stretching back to 1888...</HomepageStyles.EmphasisText>
          <HomepageStyles.BodyText>...the annual St John’s College May Ball is one of the most lavish end-of-year parties around. Spanning the length of the College’s historic courts, the ball features dozens of musicians, all-you-can eat food & drink and a world-class firework display. Famously, the theme is kept a closely-guarded secret until the night itself.</HomepageStyles.BodyText>
          </FadeIn>
        </HomepageStyles.HalfColumn>
        <HomepageStyles.HalfColumn>
          <FadeIn triggered={fadeInAnimate}>
            <img src={NewCourt} />
          </FadeIn>
        </HomepageStyles.HalfColumn>
      </HomepageStyles.MainWrapper>
    </styles.Section>
  </>
}

export default About;