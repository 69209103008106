import styled from 'styled-components';
import YouTube from 'react-youtube';
import { SectionWrapper } from './HomepageStyles';

export const Section = styled(SectionWrapper)`

`;

export const YTThumb = styled.div`
  padding-bottom: calc(100% * (9/16));
  width: 100%;
  height: 0px;
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 5px;

    cursor: pointer;
  }
`;

export const YTAspectWrapper = styled.div`
  padding-bottom: calc(100% * (9/16));
  width: 100%;
  height: 0px;
  position: relative;
  overflow: hidden;
`;

export const YT = styled(YouTube)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const ThumbnailText = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  z-index: 2;

  margin: 40px;
  p {
    margin: 5px 0;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

export const EmphasisBlock = styled.p`
  font-weight: 800;
  font-size: 1.2rem;
  letter-spacing: 0.05rem;
  span {
    letter-spacing: 0.1rem;
  }
  background-color: rgba(255,255,255,0.7);
  color: #333;
  display: inline-block;
  border-radius: 1px;
  padding: 2px 10px;

  @media (max-width: 900px) {
    background-color: ${props => props.theme.accentColorDarkBackground};
    color: #fff;
  }
`;

export const ThumbnailTitle = styled.p`
  font-family: GlamourAbsolute, serif;
  font-size: 2rem;
  

  @media (max-width: 900px) {
    /* display: inline-block; */
  }
`;

export const ThumbnailBody = styled.p`
  font-weight: 700;
  @media (max-width: 900px) {
    /* display: inline-block; */
    font-weight: 400;
  }
`;

export const EmphasisBlock2 = styled.p`
  font-weight: 800;
  font-size: 1.2rem;
  letter-spacing: 0.05rem;
  span {
    letter-spacing: 0.1rem;
  }
  background-color: rgba(255,255,255,0.7);
  color: black;
  display: inline-block;
  border-radius: 1px;
  padding: 2px 10px;

  @media (max-width: 900px) {
    background-color: ${props => props.theme.accentColorDarkBackground};
    color: black;
  }
`;

export const ThumbnailTitle2 = styled.p`
  font-family: GlamourAbsolute, serif;
  font-size: 2rem;
  
  color: black;
  @media (max-width: 900px) {
    /* display: inline-block; */
  }
  background: #ddd;
  padding: 10px;
  opacity: 0.6;
`;

export const ThumbnailBody2 = styled.p`
  font-weight: 700;
  @media (max-width: 900px) {
    /* display: inline-block; */
    font-weight: 400;
  }
  color: black;
`;

export const VideoInfoMobile = styled.div`
  display: none;

  margin: 10px 0;
  @media (max-width: 900px) {
    display: block;
    p {
      margin: 5px 0;
    }
  }
`;