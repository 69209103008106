import React, { FunctionComponent } from 'react';
import {Helmet} from "react-helmet";

interface PageTitleProps {
  title: string
}

const PageTitle:FunctionComponent<PageTitleProps> = ({title, ...rest}) => {
  return <>
    <Helmet>
      <title>{`${title ? `${title} - ` : ``}St John's College May Ball`}</title>
    </Helmet>
  </>
}

export default PageTitle;