import styled from 'styled-components';
import { WideColumn as HomepageWideColumn } from './HomepageStyles';
import { SectionWrapper } from './HomepageStyles';

export const Section = styled(SectionWrapper)`
/* background-color: #272A2D;
  color: #fff; */
`;


export const WideColumn = styled(HomepageWideColumn)`
  background: rgba(255,255,255,0.8);
  padding: 40px;
  border-radius: 5px;
  @media (max-width: 900px) {
    padding: 0px;
    & > * {
      padding: 0 20px;
    }
  }
`;

interface NameBlockProps {
  spacerBelow?: boolean
}

export const Photo = styled.div`
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const PhotoGroup = styled.div`
  display: inline-block;
  margin-right: 10px;
  display: flex;
  margin-bottom: 10px;
`;

export const SubCommBlock = styled.div`
text-transform: uppercase;
font-weight: 900;
`;

export const NameBlock = styled.div<NameBlockProps>`
  margin: 20px 20px;
  display: flex;
  gap: 50px;
  ${props => props.spacerBelow ? 'padding-bottom: 15px;' : ''}

  @media (max-width: 900px) {
    padding-bottom: 0px;
    margin: 30px 0;
    display: grid;
  }
  p {
    margin: 0;
  }
`;

export const NameWrapper = styled.div`
  display: inline-block;
  width: 200px;

  @media (max-width: 900px) {
    display: block;
    margin: 10px 0px;
  }
`;

export const Role = styled.p`
  /* font-family: GlamourAbsolute, serif; */
  font-weight: 400;
  font-size: 1rem;
  
`;

export const Name = styled.p`
  /* font-weight: 700; */
  font-family: GlamourAbsolute, serif;
  color: #333;
  font-size: 1.5rem;
`;

export const Email = styled.p`
  color: #aaa;
  font-size: 0.9rem;

  a {
    text-decoration: none;
  }
  a:hover {
    color: #666 !important;
  }
`;