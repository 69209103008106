import styled from 'styled-components';
import { SectionWrapper } from './HomepageStyles';
import { Link } from 'react-router-dom';

export const Section = styled(SectionWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

export const Navbar = styled.div`
  position: absolute;
  top: 0;
  width: calc(100% - 200px);
  font-family: GlamourAbsolute;
  font-size: 1.3rem;

  margin: 50px 100px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1200px) {
    width: 80%;
    margin: 50px 0;
  }
  @media (max-width: 900px) {
    width: 80%;
    margin: 50px auto;
  }

`;

export const NavbarLeft = styled(Link)`
  text-decoration: none;
  display: inline-block;
  display: flex;
  align-items: center;
  
  img {
    height: 2.5rem;
    display: inline-block;
    margin-right: 20px;
  }
  
  p {
    display: inline-block;
  }

  @media (max-width: 900px) {
    p {
      display: none;
    }
  }
`;

export const NavbarRight = styled.div`
  display: inline-block;
`;

export const NavbarLink = styled(Link)`
  text-decoration: none;
  margin-left: 30px;
`;

export const TextWrapper = styled.div`
  width: 70%;
  margin: auto;

  @media (max-width: 1200px) {
    width: 80%;
  }
  @media (max-width: 900px) {
    width: 95%;
  }
`;

export const Title = styled.h1`
  font-style: normal;
  text-align: center;
  font-size: 6rem;
  word-spacing: 1rem;
  span {
    font-family: GlamourAbsoluteAlternate, serif;
  }

  @media (max-width: 1200px) {
    font-size: 4rem;
  }
  @media (max-width: 900px) {
    font-size: 3rem;
    word-spacing: 0rem;
  }
`;

export const Subtitle = styled.h1`
  font-style: normal;
  text-align: center;
  font-size: 3rem;

  @media (max-width: 1200px) {
    font-size: 2.5rem;
  }
  @media (max-width: 900px) {
    font-size: 2rem;
  }
`;

interface BackgroundImageProps {
  backgroundImage: string
}

export const BackgroundImage = styled.div<BackgroundImageProps>`
  background-image: url(${props => props.backgroundImage});
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -2;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const BackgroundGradient = styled.div`
  background: linear-gradient(0deg, ${({theme}) => theme.darkBackground} 0%, rgba(0,0,0,0) 20%);
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -1;
`;

// FloatingInfo

export const Floating = styled.div`
  position: absolute;
  bottom: 60px;

  max-width: 80rem;
  @media (max-width: 900px) {
    max-width: calc(90% - 40px);
    font-size: 1rem;
    
    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const FloatingInfo = styled.div`
  border-radius: 10px;
  border: 2px solid #fff;

  font-size: 1.3rem;
  font-weight: 700;
  margin-top: 20px;

  div {
    display: inline-block;
    padding: 10px;

  }

  a {
    text-decoration: none;
    padding: 0 20px;
  }

  @media (max-width: 900px) {
    font-size: 1rem;
  }
`;

export const IconWrapper = styled.div`
`;

export const Message = styled.div`
  
`;
