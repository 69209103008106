import React, { useState, useEffect } from 'react';

// Components
import FadeIn from './../Helpers/FadeIn';

// Styles
import * as styles from './styles/HeaderStyles';

// Images
import Eagle from './../../assets/eagle.svg';
import Trees from './../../assets/trees.svg';
import NavBar from '../Helpers/NavBar';

const Header: React.FC = () => {
  useEffect(() => {
    // @ts-expect-error
    $("#toc").tocify( { selectors: "h2, h3", context: "#content"});
  }, []);
  return <>
    <styles.Section>
      <NavBar />
      <styles.TextWrapper><br /><br />
          <styles.Subtitle>Frequently Asked Questions</styles.Subtitle><br />
          <div className="container">
  <div className="row">
    <div className="col-sm-4">
        <div id="toc"></div>
        </div>
        <div className="col pl-3" style={{width: "440px"}}>
        <div id="content">
          
<h2>Tickets FAQs</h2>
<>
  <h3  >
    <strong>
      <span style={{ fontSize: "13pt" }}>
        How can I purchase a ticket to the Ball?
      </span>
    </strong>
  </h3>
  <p>
    <span style={{ fontSize: "11pt" }}>
      Those eligible to purchase tickets can do so via our ticketing website (
    </span>
    <a href="https://tickets.mayball.org/">
      <u>
        <span
          style={{
            fontSize: "11pt"
          }}
        >
          https://tickets.mayball.org/
        </span>
      </u>
    </a>
    <span style={{ fontSize: "11pt" }}>
      ). Please note that all tickets are singles this year.
    </span>
  </p>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      1st (Johnian) Release: St John’s students, fellows, staff, alumni and past May
      Ball workers are eligible
    </span>
  </p>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      2nd (General) Release: All Cambridge students and alumni are eligible
    </span>
  </p>
  <h3  >
    <strong>
      <span style={{ fontSize: "13pt" }}>When will tickets be released?</span>
    </strong>
  </h3>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      The first round of ticket release will be open from January 22nd - February 2nd.
      The second round, a general release open to all Cambridge students and
      alumni, will take place during week commencing 5th February.
    </span>
  </p>
  <h3  >
    <strong>
      <span style={{ fontSize: "13pt" }}>How do ticket allocations work?</span>
    </strong>
  </h3>
  <p  >
    <span style={{ fontSize: "11pt" }}>
    In the first release, St John’s students can purchase a maximum of 1 ticket and 3 further guest tickets. St John’s alumni are guaranteed 2 tickets. The remaining alumni and 1888 Club tickets are allocated on a first-come, first-served basis subject to payment confirmation. Guest tickets may only be bought if they are purchased alongside a Johnian ticket.
    </span>
  </p>
  <p  >
    <span style={{ fontSize: "11pt" }}>
    In the second release, all tickets are allocated on a first-come first-served basis, with 24 hours given to make the payment after it has been selected.
    </span>
  </p>
  <h3  >
    <strong>
      <span style={{ fontSize: "13pt" }}>How much do tickets cost?</span>
    </strong>
  </h3>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      The cost of basic tickets (with no upgrades) depends on the status of the
      lead applicant:&nbsp;
    </span>
  </p>
  <ul>
    <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
      <p  >
        <span style={{ fontSize: "11pt" }}>
          Current students of St John’s:&nbsp;
        </span>
      </p>
      <ul>
        <li style={{ listStyleType: "circle", fontSize: "11pt" }}>
          <p  >
            <span style={{ fontSize: "11pt" }}>
              £215 for your first single&nbsp;
            </span>
          </p>
        </li>
        <li style={{ listStyleType: "circle", fontSize: "11pt" }}>
          <p  >
            <span style={{ fontSize: "11pt" }}>
              £265 for additional guest singles
            </span>
          </p>
        </li>
      </ul>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
      <p  >
        <span style={{ fontSize: "11pt" }}>College Fellows:</span>
      </p>
      <ul>
        <li style={{ listStyleType: "circle", fontSize: "11pt" }}>
          <p  >
            <span style={{ fontSize: "11pt" }}>£230 for your first single</span>
          </p>
        </li>
        <li style={{ listStyleType: "circle", fontSize: "11pt" }}>
          <p  >
            <span style={{ fontSize: "11pt" }}>
              £280 for additional guest singles
            </span>
          </p>
        </li>
      </ul>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
      <p  >
        <span style={{ fontSize: "11pt" }}>Alumni of St John’s:&nbsp;</span>
      </p>
      <ul>
        <li style={{ listStyleType: "circle", fontSize: "11pt" }}>
          <p  >
            <span style={{ fontSize: "11pt" }}>
              £280 for your first two singles
            </span>
          </p>
        </li>
        <li style={{ listStyleType: "circle", fontSize: "11pt" }}>
          <p  >
            <span style={{ fontSize: "11pt" }}>
              £320 for additional guest singles
            </span>
          </p>
        </li>
      </ul>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
      <p  >
        <span style={{ fontSize: "11pt" }}>College Staff:&nbsp;</span>
      </p>
      <ul>
        <li style={{ listStyleType: "circle", fontSize: "11pt" }}>
          <p  >
            <span style={{ fontSize: "11pt" }}>
            £265 for a single ticket
            </span>
          </p>
        </li>
      </ul>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
      <p  >
        <span style={{ fontSize: "11pt" }}>External students:</span>
      </p>
      <ul>
        <li style={{ listStyleType: "circle", fontSize: "11pt" }}>
          <p  >
            <span style={{ fontSize: "11pt" }}>£265 for a single&nbsp;</span>
          </p>
        </li>
      </ul>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
      <p  >
        <span style={{ fontSize: "11pt" }}>External alumni:</span>
      </p>
      <ul>
        <li style={{ listStyleType: "circle", fontSize: "11pt" }}>
          <p  >
            <span style={{ fontSize: "11pt" }}>£320 for a single</span>
          </p>
        </li>
      </ul>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
      <p  >
        <span style={{ fontSize: "11pt" }}>1888 Club:</span>
      </p>
      <ul>
        <li style={{ listStyleType: "circle", fontSize: "11pt" }}>
          <p  >
            <span style={{ fontSize: "11pt" }}>£450 for a single</span>
          </p>
        </li>
      </ul>
    </li>
  </ul>
  <p  >
    <em>
      <span style={{ fontSize: "11pt" }}>
        Johnians in receipt of a St John’s and/or Cambridge bursary are eligible
        for a discount
      </span>
    </em>
  </p>
  <h3  >
    <strong>
      <span style={{ fontSize: "13pt" }}>Are upgrades available?</span>
    </strong>
  </h3>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      Yes, you can opt to purchase an upgrade for your ticket when you are
      buying it. The prices of upgrades are as follows:
    </span>
  </p>
  <ul>
    <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
      <p  >
        <span style={{ fontSize: "11pt" }}>Fast Track: £90</span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
      <p  >
        <span style={{ fontSize: "11pt" }}>Dining: £130</span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
      <p  >
        <span style={{ fontSize: "11pt" }}>Breakfast: £50</span>
      </p>
    </li>
  </ul>
  <h3  >
    <strong>
      <span style={{ fontSize: "13pt" }}>When do I need to pay?</span>
    </strong>
  </h3>
  <p  >
    <span style={{ fontSize: "11pt" }}>
    Tickets selected will need to be paid for up-front. For the first release, payment must be made before the end of the release window. For the second release, after your tickets have been confirmed on the system, you will have 24 hours to make the payment. After 24 hours, your application will be cancelled if a payment is not received.
</span>
</p>
<p  >
    <span style={{ fontSize: "11pt" }}>
  
Payments will be made via bank transfer, except international payments which will be made via Stripe. More information will be given at the point of purchase. Once this is completed, your tickets will be valid. 
</span>
</p>
<p  >
    <span style={{ fontSize: "11pt" }}>
Payments do not need to be made by one transfer. If your guests wish to transfer directly to the Ball, please ensure they use your unique reference code.
      </span>
  </p>
  <h3  >
    <strong>
      <span style={{ fontSize: "13pt" }}>
        I have paid for my ticket, but it does not show on the system?&nbsp;
      </span>
    </strong>
  </h3>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      All payments should be uploaded to the system within three days. If it has
      not been uploaded within a week, please get in touch with&nbsp;
    </span>
    <a href="mailto:treasurer@mayball.org">
      <u>
        <span style={{ fontSize: "11pt" }}>
          treasurer@mayball.org
        </span>
      </u>
    </a>
    <span style={{ fontSize: "11pt" }}>.&nbsp;</span>
  </p>
  <p  >
    <span style={{ fontSize: "11pt" }}>For</span>
    <strong>
      <span style={{ fontSize: "11pt" }}>
        &nbsp;international bank transfers
      </span>
    </strong>
    <span style={{ fontSize: "11pt" }}>
      , please only get in contact after a week as they can take longer to
      confirm. It can also be harder to identify payments especially if not
      under the ticket holder’s name. Please get in touch if payments are not in
      your name and have not come through within a week. Please also ensure the
      amount paid is correct once exchange rates and transfer fees are
      applied.&nbsp;
    </span>
  </p>
  <h3  >
    <strong>
      <span style={{ fontSize: "13pt" }}>
        I bought a ticket to the 2020 May Ball. What will happen to my ticket?
      </span>
    </strong>
  </h3>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      As detailed in the communications last year, the 2023 May Ball was the
      last opportunity to roll over tickets from the cancelled 2020 May Ball.
      Any tickets that were not claimed last year unfortunately cannot be used
      for the 2024 May Ball, as they are no longer valid.
    </span>
  </p>
  <h3  >
    <strong>
      <span style={{ fontSize: "13pt" }}>
        I worked the 2023 May Ball, how can I claim my ticket?
      </span>
    </strong>
  </h3>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      CRSids of all 2023 workers have been added to the system, allowing you to
      create an account and select your ticket in the first release. Please get
      in touch with&nbsp;
    </span>
    <a href="mailto:tickets@mayball.org">
      <u>
        <span style={{  fontSize: "11pt" }}>
          tickets@mayball.org
        </span>
      </u>
    </a>
    <span style={{ fontSize: "11pt" }}>
      &nbsp;if there is an issue with this. Unfortunately, this ticket cannot be
      rolled over to future balls but you can request a name change if you can
      no longer attend. If this is done before the name change deadline there
      will be no charge.
    </span>
  </p>
  <h3  >
    <strong>
      <span style={{ fontSize: "13pt" }}>
        Can I change the names on my tickets?
      </span>
    </strong>
  </h3>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      Yes. The names of guests can be changed free of charge before&nbsp;
    </span>
    <strong>
      <span style={{ fontSize: "11pt" }}>Sunday</span>
    </strong>
    <span style={{ fontSize: "11pt" }}>&nbsp;</span>
    <strong>
      <span style={{ fontSize: "11pt" }}>31st March&nbsp;</span>
    </strong>
    <span style={{ fontSize: "11pt" }}>
      at 11:59pm. After this deadline, name changes will cost £20 until the 6th
      May, £40 until the 3rd June, and £60 until the 16th June. After that, name
      changes will only be available in exceptional circumstances at the
      discretion of the Committee. If you believe you have an exceptional reason
      to request a name change after the 16th June, please contact&nbsp;
    </span>
    <a href="mailto:tickets@mayball.org">
      <u>
        <span style={{ fontSize: "11pt" }}>
          tickets@mayball.org
        </span>
      </u>
    </a>
    <span style={{ fontSize: "11pt" }}>
      . The name of the lead applicant cannot be changed.
    </span>
  </p>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      Please note that changing the Dining and 1888 dietary requirements may not
      be possible after the 23rd April. Please contact&nbsp;
    </span>
    <a href="mailto:food@mayball.org">
      <u>
        <span style={{  fontSize: "11pt" }}>
          food@mayball.org
        </span>
      </u>
    </a>
    <span style={{ fontSize: "11pt" }}>&nbsp;and&nbsp;</span>
    <a href="mailto:tickets@mayball.org">
      <u>
        <span style={{ fontSize: "11pt" }}>
          tickets@mayball.org
        </span>
      </u>
    </a>
    <span style={{ fontSize: "11pt" }}>
      &nbsp;to make a request to change the names and/or dietary requirements of
      these tickets. &nbsp;
    </span>
  </p>
  <h3  >
    <strong>
      <span style={{ fontSize: "13pt" }}>What does the ticket include?</span>
    </strong>
  </h3>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      The standard ticket provides entry to the Ball and the food, drink and
      entertainment within. You can expect the college to be transformed with
      beautiful decorations that illustrate our theme, which is kept secret
      until the day of the Ball. There will be an array of bars and food stands,
      as well as a headline act, a fireworks display and plenty of other
      surprises to keep you entertained throughout the night.
    </span>
  </p>
  <h3  >
    <strong>
      <span style={{ fontSize: "13pt" }}>What do the upgrades include?</span>
    </strong>
  </h3>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      The Dining upgrade provides early entry to the ball for a sit-down dinner
      in the Senior Combination Room to start the evening off in style. The
      courses will conclude just in time for the fireworks.
    </span>
  </p>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      The Breakfast upgrade includes a formal breakfast in the Senior
      Combination Room in the early hours of the morning. Rest your weary feet
      and soak up the experience of dining in the room where the D-Day landings
      were planned!&nbsp;
    </span>
  </p>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      The Fast-track upgrade allows guests to skip the queue and enter the Ball
      into Chapel Court early. Expect an hour of drinks, canapes and
      entertainment before the Ball officially begins at 9:00pm.
    </span>
  </p>
  <h3  >
    <strong>
      <span style={{ fontSize: "13pt" }}>What is the 1888 club?</span>
    </strong>
  </h3>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      Members of our 1888 Club are welcomed to the Ball with an exclusive
      reception held in the Master’s Lodge garden. Throughout the night they
      will be given access to the exclusive Wordsworth room, where the author
      lived as an undergraduate at the College over 200 years ago. Here, guests
      will be welcomed with a curated selection of food and drinks, along with
      live music and entertainment.
    </span>
  </p>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      Please note these tickets are separate from the Dining upgrade. All those
      who are eligible for a standard ticket are also eligible for 1888 Club
      tickets.
    </span>
  </p>
  <h3  >
    <strong>
      <span style={{ fontSize: "13pt" }}>
        What happens if someone tries to sell a ticket to the Ball for a profit?
      </span>
    </strong>
  </h3>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      The resale or purchase of tickets above the cost price is not permitted.
      If a ticket holder is found to have sold or attempted to sell their ticket
      for more than they paid, they may have all their tickets cancelled without
      refund.
    </span>
  </p>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      If you have bought a ticket for more than the cost price, please report
      the seller to the Committee at&nbsp;
    </span>
    <a href="mailto:tickets@mayball.org">
      <u>
        <span style={{ fontSize: "11pt" }}>
          tickets@mayball.org
        </span>
      </u>
    </a>
    <span style={{ fontSize: "11pt" }}>
      . Your ticket will be honoured. Similarly, if you have seen someone
      attempt to sell a ticket for more than cost price, please report the
      seller to the Committee. You may be offered the right to buy a ticket in
      return.
    </span>
  </p>
</>

<h2>The Night of the Ball FAQs</h2>
<>
  <h3  >
    <strong>
      <span style={{ fontSize: "13pt" }}>
        What is the dress code for the Ball?
      </span>
    </strong>
  </h3>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      The dress code is strict black tie or national dress. Dresses must be
      below the knee in length. White tie is not permitted.
    </span>
  </p>
  <h3  >
    <strong>
      <span style={{ fontSize: "13pt" }}>
        What do I need to bring to the Ball?
      </span>
    </strong>
  </h3>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      You will be required to bring a valid form of photo identification, such
      as a driving licence or passport.&nbsp;
    </span>
    <strong>
      <span style={{ fontSize: "11pt" }}>
        Your camCARD or university card is not a valid form of
        identification.&nbsp;
      </span>
    </strong>
    <span style={{ fontSize: "11pt" }}>
      Please ensure that the name on your ID matches the name on your
      ticket.&nbsp; This is a 18+ event. All IDs will be checked upon entrance.
    </span>
  </p>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      Your ticket will be sent to you via email after payment confirmation,
      including a QR code that you will be required to present upon entry.
    </span>
  </p>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      If you wish to bring extra layers or a change of shoes, we provide a
      cloakroom.
    </span>
  </p>
  <h3  >
    <strong>
      <span style={{ fontSize: "13pt" }}>What are the admission times?</span>
    </strong>
  </h3>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      Entrance to the Ball will begin from 9pm for the standard tickets and at
      8pm for the Fast Track. The Fellows Reception, for Fellows, their guests
      and 1888 Club members, will begin at 7.30pm. Dining guests should enter
      the Ball before 7pm to be seated.
    </span>
  </p>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      The last entrance to the Ball via Queens Gate Road is at 10pm. If you
      cannot arrive before this time, entrance may be allowed at the discretion
      of the Committee from a side entrance. Please email&nbsp;
    </span>
    <a href="mailto:tickets@mayball.org">
      <u>
        <span style={{ fontSize: "11pt" }}>
          tickets@mayball.org
        </span>
      </u>
    </a>
    <span style={{ fontSize: "11pt" }}>
      &nbsp;after you have secured a ticket to request this.
    </span>
  </p>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      The Ball will end at 6am. A &nbsp;survivor's picture will be taken for all
      the guests that have made it to the end of the night.
    </span>
  </p>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      If you are to leave at any point during the Ball, you will not be allowed
      to re-enter the venue.
    </span>
  </p>
  <h3  >
    <strong>
      <span style={{ fontSize: "13pt" }}>Where do I queue?</span>
    </strong>
  </h3>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      For Standard tickets and tickets with only a Breakfast upgrade, queuing
      will start at the Queen’s Gate on Queen’s Road. Further guidance on
      arrival and queuing will be provided nearer the time.
    </span>
  </p>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      We have an accessible entrance at Cripps Porters’ Lodge. You will be asked
      for any accessibility requirements during our ticketing process. If you
      have any questions or if your accessibility requirements change nearer the
      time, please do not hesitate to contact&nbsp;
    </span>
    <a href="mailto:security@mayball.org">
      <u>
        <span style={{ fontSize: "11pt" }}>
          security@mayball.org
        </span>
      </u>
    </a>
    <span style={{ fontSize: "11pt" }}>&nbsp;and&nbsp;</span>
    <a href="mailto:tickets@mayball.org">
      <u>
        <span style={{  fontSize: "11pt" }}>
          tickets@mayball.org
        </span>
      </u>
    </a>
    <span style={{ fontSize: "11pt" }}>.&nbsp;</span>
  </p>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      For our 1888 Club members, and anyone with Fast Track and Dining upgrades
      the entrance will be the Forecourt Porters’ Lodge. Forecourt Porters’
      Lodge is an accessible entrance.
    </span>
  </p>
  <h3  >
    <strong>
      <span style={{ fontSize: "13pt" }}>Is the Ball accessible?</span>
    </strong>
  </h3>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      The Ball is signed up to the Cambridge SU Access-A-Ball scheme. We have
      pledged according to the aims of the scheme:
    </span>
  </p>
  <ul>
    <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
      <p  >
        <span style={{ fontSize: "11pt" }}>
          To improve accessibility within the grounds of the Ball
        </span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
      <p  >
        <span style={{ fontSize: "11pt" }}>
          To provide for as broad a range of access needs as possible so that
          all guests feel welcomed, cared for and may enjoy their evening
        </span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
      <p  >
        <span style={{ fontSize: "11pt" }}>
          To have a designated committee member in charge of access requirement,
          trained by the Access-A-Ball scheme to ensure our event is accessible
          - please contact&nbsp;
        </span>
        <a href="mailto:security@mayball.org">
          <u>
            <span style={{ fontSize: "11pt" }}>
              security@mayball.org
            </span>
          </u>
        </a>
        <span style={{ fontSize: "11pt" }}>
          &nbsp;for any specific accessibility queries
        </span>
      </p>
    </li>
  </ul>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      If you wish to find out more about Access-A-Ball, please click&nbsp;
    </span>
    <a href="https://www.disabled.cusu.cam.ac.uk/access-a-ball/">
      <u>
        <span style={{ fontSize: "11pt" }}>here</span>
      </u>
    </a>
    <span style={{ fontSize: "11pt" }}>&nbsp;</span>
  </p>
  <h3  >
    <strong>
      <span style={{ fontSize: "13pt" }}>
        What do I do if I have a dietary requirement?
      </span>
    </strong>
  </h3>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      Allergens will be clearly marked at each food and drink stall. However as
      these are sourced externally, we cannot fully ensure that no allergens
      will be present. If you apply for the 1888 Club/Dining/Breakfast upgrades,
      you will be asked for your dietary requirements during our ticketing
      process. If you have any more queries, please contact&nbsp;
    </span>
    <a href="mailto:food@mayball.org">
      <u>
        <span style={{ fontSize: "11pt" }}>
          food@mayball.org
        </span>
      </u>
    </a>
    <span style={{ fontSize: "11pt" }}>&nbsp;or&nbsp;</span>
    <a href="mailto:drink@mayball.org">
      <u>
        <span style={{ fontSize: "11pt" }}>
          drink@mayball.org
        </span>
      </u>
    </a>
    <span style={{ fontSize: "11pt" }}>.&nbsp;</span>
  </p>
</>


<h2>Any other questions?</h2>
<>
  <h3  >
    <strong>
      <span style={{ fontSize: "13pt" }}>
        Do our workers receive a fair wage and treatment?
      </span>
    </strong>
    <strong>
      <span style={{ fontSize: "13.999999999999998pt" }}>&nbsp;</span>
    </strong>
  </h3>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      We pay hourly wages above the national minimum wage to our workers, more
      precisely between £12 and £20 an hour, depending on whether it is a junior
      or senior role. Additionally, this year we have sought to reduce the
      length of our junior worker shifts to improve the working experience. Our
      more senior workers may be required to work longer shifts but will all be
      required to take regular breaks as per legal guidelines.
    </span>
  </p>
  <h3  >
    <strong>
      <span style={{ fontSize: "13.999999999999998pt" }}>
        Can I work at the Ball?
      </span>
    </strong>
  </h3>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      Unfortunately, our worker applications have closed. Please check
      our social media for further updates! &nbsp;
    </span>
  </p>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      For any further queries, please contact the relevant committee member
      -&nbsp;
    </span>
    <a href="mailto:employment@mayball.org">
      <u>
        <span style={{  fontSize: "11pt" }}>
          employment@mayball.org
        </span>
      </u>
    </a>
    <span style={{ fontSize: "11pt" }}>,&nbsp;</span>
    <a href="mailto:food@mayball.org">
      <u>
        <span style={{  fontSize: "11pt" }}>
          food@mayball.org
        </span>
      </u>
    </a>
    <span style={{ fontSize: "11pt" }}>,&nbsp;</span>
    <a href="mailto:drink@mayball.org">
      <u>
        <span style={{ fontSize: "11pt" }}>
          drink@mayball.org
        </span>
      </u>
    </a>
    <span style={{ fontSize: "11pt" }}>.&nbsp;</span>
  </p>
  <h3  >
    <strong>
      <span style={{ fontSize: "13pt" }}>Is the Ball sustainable?</span>
    </strong>
  </h3>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      We work hard to ensure sustainability is at the forefront of the Ball. We
      partner with local suppliers of food, drink and decorations to help reduce
      our carbon footprint whilst promoting the growth of local businesses. We
      explore ways of reducing waste for the duration of the Ball, including
      reducing our use of single-use plastic and resources, repurposing and
      upcycling as much as possible, and preventing usable and recyclable items
      from going to landfill.  We have signed up to join the Sustain-a-Ball accreditation scheme to ensure all sustainability measures are taken into consideration.
    </span>
  </p>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      For any sustainability queries and suggestions please contact&nbsp;
    </span>
    <a href="mailto:sustainability@mayball.org">
      <u>
        <span style={{ fontSize: "11pt" }}>
          sustainability@mayball.org
        </span>
      </u>
    </a>
    <span style={{ fontSize: "11pt" }}>.&nbsp;</span>
  </p>
  <h3  >
    <strong>
      <span style={{ fontSize: "13pt" }}>
        Does the Ball fundraise for charity?
      </span>
    </strong>
  </h3>
  <p  >
    <span style={{ fontSize: "11pt" }}>St John’s</span>
    <span style={{  fontSize: "11pt" }}>
      &nbsp;College currently supports three charities:&nbsp;
    </span>
    <a href="https://www.youngminds.org.uk/">
      <u>
        <span
          style={{
            fontSize: "11pt"
          }}
        >
          Young Minds
        </span>
      </u>
    </a>
    <span style={{ fontSize: "11pt" }}>
      ,&nbsp;
    </span>
    <a href="https://www.jimmyscambridge.org.uk/">
      <u>
        <span
          style={{
            fontSize: "11pt"
          }}
        >
          Jimmy's Cambridge
        </span>
      </u>
    </a>
    <span style={{ fontSize: "11pt" }}>
      &nbsp;and&nbsp;
    </span>
    <a href="https://www.mayweekalternative.org.uk/">
      <u>
        <span
          style={{
            fontSize: "11pt"
          }}
        >
          May Week Alternative
        </span>
      </u>
    </a>
    <span style={{  fontSize: "11pt" }}>.</span>
  </p>
  <p  >
    <span style={{ fontSize: "11pt" }}>
      Young Mind supports children, young people and parents experiencing mental
      health difficulties. Jimmy’s is one of Cambridge’s longest running
      charities that battles homelessness through provision of shelter and food.
      May Week Alternative matches donations by 100% to the Against Malaria
      Foundation. Money is raised from a combination of activities: profits from
      posters and paper tickets, half of name change fees and a lump sum fee
      after the ball.
    </span>
  </p>
  <h3  >
    <strong>
      <span style={{ fontSize: "13pt" }}>Any other questions?</span>
    </strong>
  </h3>
  <p
    style={{
      textAlign: "justify",
    }}
  >
    <strong>
      <span style={{ color: "#ffffff", fontSize: "11pt" }}>
        If you cannot find the answer to your query above, please consult our
      </span>
    </strong>
    <a href="https://mayball.org/file/2024-sjcmb-terms.pdf">
      <strong>
        <span style={{ fontSize: "11pt" }}>&nbsp;</span>
      </strong>
      <strong>
        <u>
          <span style={{ 
            fontSize: "11pt" }}>
            Terms and Conditions
          </span>
        </u>
      </strong>
    </a>
    <strong>
      <span style={{ fontSize: "11pt" }}>
        &nbsp;for further information. For more specific queries, please contact
        the relevant Committee member.
      </span>
    </strong>
  </p>
</>

        </div>
      </div>
    </div>
  </div>
      </styles.TextWrapper>
    </styles.Section>
  </>
}

export default Header;
