import styled from 'styled-components';
import { SectionWrapper } from './HomepageStyles';

export const Section = styled(SectionWrapper)`
  background-color: ${({theme}) => theme.darkBackground};
  color: #fff;

  p {
    margin: 10px 40px;
  }

  img {
    margin: 40px;
  }
`;

export const EmphasisText = styled.p`
  font-family: GlamourAbsolute, serif;
  font-size: 3rem;
`;

export const BodyText = styled.p`
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
`;

