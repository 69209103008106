import React from 'react';

// Components
import Header from './../components/Homepage/Header';
import About from './../components/Homepage/About';
import Video from './../components/Homepage/Video';
import Sponsor from './../components/Homepage/Sponsor';
import Committee from './../components/Homepage/Committee';
import Work from './../components/Homepage/Work';
import Charities from './../components/Homepage/Charities';
import Footer from '../components/Footer/Footer';

// Styles

const Homepage = () => {
  return <>
    <Header />
    <About />
    <Video />
    
    <Committee />
    <Charities />
    {/*<MWA />*/}
    <Footer /> 
  </>
}

export default Homepage;
