import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const MainWrapper = styled.div`
  background-color: ${({theme}) => theme.darkBackground};
`;

export const ContentWrapper = styled.div`
  width: 70%;
  max-width: 80rem;
  margin: auto;
  padding: 50px 0;
  @media (max-width: 1200px) {
    width: 80%;
  }
  @media (max-width: 900px) {
    width: 90%;
    padding: 50px 0;
  }
`;

interface FooterDividerProps {
  onlyMobile?: boolean
}

export const FooterDivider = styled.div<FooterDividerProps>`
  display: ${ ({onlyMobile}) => onlyMobile ? 'none' : 'block'};
  background-color: #aaa;
  width: 100%;
  height: 1px;
  margin: 30px 0;
  @media (max-width: 900px) {
    display: block;
  }
`;

export const WideSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 900px) {
    display: block;
  }
`;

interface ContentSectionProps {
  width: string
  right?: boolean
  padding?: boolean
}

export const ContentSection = styled.div<ContentSectionProps>`
  width: ${ ({width}) => width};
  display: inline-block;
  text-align: ${ ({right}) => right ? 'right' : 'left'};
  ${ ({padding}) => padding ? 'padding-left: 40px;' : ''}
  @media (max-width: 900px) {
    width: 100%;
    text-align: left;
    display: block;
    padding-left: 0;
  }
`;

export const SectionTitle = styled.p`
  margin: 10px 0;
  font-size: 1rem;
  font-weight: 700;
  color: ${({theme}) => theme.accentColorDarkBackground};
  `;


export const Type = styled.p`
  color: #fff;
  font-size: 1.2rem;
  display: inline-block;
  font-weight: 700;
  padding-right: 10px;
`;

export const SocialLink = styled.a`
  display: inline-block;
  color: #fff;
  font-size: 1.2rem;
  margin-left: 20px;
  transition: translateY 0.5s ease;
  &:hover {
    transform: translateY(-2px);
  }
  @media (max-width: 900px) {
    margin-left: 0px;
    margin-right: 20px;
  }
`;

export const Logo = styled.img`
  width: 100%;
  margin: 10px 0;
  @media (max-width: 900px) {
    display: none;
  }
`;

export const LogoMobile = styled.div`
  display: none;
  text-align: center;
  @media (max-width: 900px) {
    display: block;
  }
`;

export const LogoMobileImage = styled.img`
  height: 80px;
`;
